import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    filterIsOpen : false,
    recipeData   : [],
    recipeFilters: {
      field_category: '',
      field_kitchen : '',
      field_meal    : '',
    },
    selectedRecipe: null,
  },
  getters: {
    filterIsOpen : state => state.filterIsOpen,
    recipeData   : state => state.recipeData,
    recipeFilters: state => state.recipeFilters,
    recipesArray : (state) => {
      if ('undefined' === typeof state.recipeData.data) {
        return [];
      }

      return Array.from(state.recipeData.data.values()).reduce((acc, recipe) => {
        for (const fieldName in recipe.relationships) {
          if (false === state.recipeFilters.hasOwnProperty(fieldName)) {
            continue;
          }

          if ('' === state.recipeFilters[fieldName]) {
            recipe.showInOverview = true;
          } else if (recipe.relationships[fieldName].data instanceof Array) {
            recipe.showInOverview = recipe.relationships[fieldName].data
              .some(term => term.id === state.recipeFilters[fieldName]);
          } else if (recipe.relationships[fieldName].data instanceof Object) {
            recipe.showInOverview = recipe.relationships[fieldName].data.id === state.recipeFilters[fieldName];
          } else {
            recipe.showInOverview = true;
          }

          if (false === recipe.showInOverview) {
            break;
          }
        }
        acc.push(recipe);
        return acc;
      }, []);
    },
    getRecipeFilterItems: state => type => ('undefined' === typeof state.recipeData.included
      ? []
      : state.recipeData.included.reduce((acc, item) => {
        if (type === item.type) {
          return Object.assign(acc, { [item.id]: item.attributes.name });
        }

        return acc;
      }, {})),
    recipesMap       : state => state.recipeData.data,
    selectedRecipe   : state => state.selectedRecipe,
    getRecipeImageUrl: state => (recipe, imageStyle) => {
      if (null === recipe.relationships.field_image.data) {
        return null;
      }
      const imageId = recipe.relationships.field_image.data.id,
            imageObject = state.recipeData.included.find(item => imageId === item.id);

      return imageObject.meta.derivatives[imageStyle];
    },
  },
  mutations: {
    filterToggle (state) {
      state.filterIsOpen = !state.filterIsOpen;
    },
    selectRecipe (state, recipe) {
      state.selectedRecipe = recipe;
    },
    setRecipeFilters (state, recipeFilters) {
      state.recipeFilters = recipeFilters;
    },
    storeRecipeData (state, recipes) {
      state.recipeData = recipes;
    },
  },
  actions: {
    searchRecipes ({ commit }) {
      fetch('/api/recipe?include=field_image,field_kitchen,field_meal,field_category&sort=-changed&_consumer_id=d39bf958-e756-4199-b928-d726ba5a12a9')
        .then(response => response.json())
        .then((json) => {
          const data = new Map(),
                cookingTimeMap = {
                  10: '0 tot 10 minuten',
                  20: '10 tot 20 minuten',
                  30: '30 tot 60 minuten',
                };

          for (const recipe of json.data) {
            recipe.attributes.field_cooking_time = cookingTimeMap[recipe.attributes.field_cooking_time];
            recipe.showInOverview = true;
          }

          json.data = json.data.reduce((acc, recipe) => data.set(recipe.attributes.path.alias, recipe), data);

          commit('storeRecipeData', json);
        });
    },

    selectRecipe ({ commit }, recipe) {
      commit('selectRecipe', recipe);
    },
  },
});
