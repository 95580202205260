<template>
  <footer>
    <a
      href="https://www.facebook.com/kokenenrecepten"
      class="facebook-page-link">
      <font-awesome-icon
        :icon="['fab', 'facebook-square']"
        class="icon"/>
      Bezoek onze Facebook pagina
    </a>
  </footer>
</template>
<script>
export default {
  name: 'KerFooter',
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables";

footer {
    background-color: $primary-color;
    margin: 0;
    padding: 20px;

    a, a:visited {
        color: #ffffff;
    }

    .icon {
        font-size: 32px;
        margin-right: 5px;
    }
}

</style>
