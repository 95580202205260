import Vue from 'vue';
import Router from 'vue-router';

const RecipeDetail = () => import(/* webpackChunkName: "recipes-detail" */ '@/components/RecipeDetail.vue'),
      RecipesView = () => import(/* webpackChunkName: "recipes-view" */ '@/components/RecipesView.vue'),
      CookieStatement = () => import(/* webpackChunkName: "cookie-statement" */ '@/components/CookieStatement.vue');

Vue.use(Router);

export default new Router({
  mode  : 'history',
  routes: [
    {
      path     : '/',
      alias    : '/recepten',
      name     : 'Recipes',
      component: RecipesView,
      children : [
        {
          path     : '/recepten/:title',
          name     : 'RecipeDetail',
          component: RecipeDetail,
        },
      ],
    },
    {
      path     : '/privacy-en-cookieverklaring',
      name     : 'CookieStatement',
      component: CookieStatement,
    },
  ],
  scrollBehavior () {
    return {
      x: 0,
      y: 0,
    };
  },
});
