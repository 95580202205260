<template>
  <div class="menu-bar">
    <div class="container">
      <div class="site-name">
        <router-link
          :to="{name: 'Recipes'}"
          tag="a">
          <font-awesome-icon
            class="icon"
            icon="utensils"/>
          Koken en recepten
        </router-link>
      </div>
      <nav class="main-menu">
        <router-link
          :to="{name: 'Recipes'}"
          tag="a">Recepten
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuBar',
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.menu-bar {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    height: 70px;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}

.container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 1112px;
    padding: 10px;
    width: 100%;
}

.site-name {
    font-size: 36px;
    a {
        color: $primary-color;
        text-decoration: none;
    }

    .icon {
        margin-right: 10px;
    }
}

.recipe-filter {
    align-self: flex-end;
}

.main-menu {
    display: none;

    @media (min-width: 740px) {
        display: block;
    }

    a {
        color: $primary-color;
        font-size: 24px;
    }
}
</style>
