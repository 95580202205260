<template>
  <div id="app">
    <menu-bar/>
    <router-view/>
    <ker-footer/>
    <cookie-law button-text="Akkoord">
      <div slot="message">
        Deze site gebruikt cookies.
        <router-link :to="{name: 'CookieStatement'}">Meer informatie</router-link>
      </div>
    </cookie-law>
  </div>

</template>

<script>
import CookieLaw from 'vue-cookie-law';
import MenuBar from '@/components/MenuBar.vue';
import KerFooter from '@/components/KerFooter.vue';

export default {
  name      : 'App',
  components: { CookieLaw, KerFooter, MenuBar },
  metaInfo  : {
    titleTemplate: '%s | Koken en Recepten',
  },
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Roboto:300');
  @import url('https://fonts.googleapis.com/css?family=Slabo+27px:400');

  body {
    background-color: #f5f5f5;
    margin: 0;
  }

  p {
    line-height: 28px;
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0 20px;
  }

  li {
    margin: 10px 0;
  }

  #app .Cookie .Cookie__button {
    background: #54af78;

    a, a:visited {
      color: #54af78;
    }
  }
</style>

<style lang="scss" scoped>
  @import "~@/styles/_variables.scss";

  #app {
    font-family: 'Slabo 27px', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    text-align: center;
  }
</style>
