// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Meta from 'vue-meta';
import VueAnalytics from 'vue-analytics';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faClock,
  faCoins,
  faEuroSign,
  faFilter,
  faGlobeAfrica,
  faTag,
  faTimes,
  faUsers,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App';
import router from './router';
import store from './store';

Vue.use(Meta);

'production' === process.env.NODE_ENV && Vue.use(VueAnalytics, {
  id: 'UA-124181026-1',
});

library.add(faClock, faCoins, faEuroSign, faFacebookSquare, faFilter, faGlobeAfrica, faTag, faTimes, faUsers, faUtensils);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
